export const CAMELCASE_SETTING_VALUES = [
  'cardViewHeaderVisible',
  'cardViewReorderVisible',
  'cardViewRestorationReorderVisible',
  'horizontalCardViewHeaderVisible',
  'masterListSelection',
  'columnEditable',
  'columnOrder',
  'columnVisible',
  'controlIndividualRow',
  'controlMinWidth',
  'controlWidth',
  'headerFieldsEnabled',
  'masterListSelection',
  'requiredFields',
  'visibleColumns',
  'detailColumnVisible'
];
